import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Index from "./components/Index";
import "./App.css";

class App extends React.Component {
  state = {};
  componentDidMount() {}
  render() {
    return (
      <>
        <Router>
          <Switch>
            <Route exact path="/" component={() => <Index type="home" />} />
            <Route
              path="/product/:type"
              component={() => <Index type="productView" />}
            />
            <Route
              exact
              path="/request-a-callback"
              component={() => <Index type="callbackform" />}
            />
            <Route
              exact
              path="/contact"
              component={() => <Index type="contact" />}
            />
          </Switch>
        </Router>
      </>
    );
  }
}

export default App;
