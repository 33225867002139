import React from "react";
import moment from "moment";
import logo_white from "../images/logo-white.svg";
import * as Icon from "react-feather";

class Footer extends React.Component {
  render() {
    return (
      <>
        <div className="section footer-flex">
          <div className="container py-5">
            <div>
              <div clas="logo">
                <img src={logo_white} alt="ATOZ Furnishers" width="100px" />
              </div>
              <div className="copyright-text mt-4">
                &copy; {moment().format("YYYY")} ATOZ Furnishers Pvt. Ltd. All
                Rights Reserved.
              </div>
            </div>
            <div className="mt-5">
              <h5
                className="h5"
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "#fff",
                }}
              >
                Made With{" "}
                <Icon.Heart
                  className="mx-1"
                  style={{ fill: "#ff4157", color: "#ff4157" }}
                ></Icon.Heart>{" "}
                in India. Crafted By NameGrill.
              </h5>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Footer;
