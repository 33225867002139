import React from "react";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import * as Icon from "react-feather";
import Footer from "./footer";
import moment from "moment";
import logo_white from "../images/logo-white.svg";
import logo_red from "../images/logo-red.svg";
import "../bootstrap.css";
import "../default-min.css";
import "../Design.css";
import "../App.css";

class Index extends React.Component {
  state = {
    scrolledLevelPassed: false,
    product_carousel: [
      {
        link: "./images/products/Roller-Blinds.jpg",
        title: "Roller Blinds",
      },
      {
        link: "../images/products/Zebra-Blinds.jpg",
        title: "Zebra Blinds",
      },
      {
        link: "../images/products/Wooden-Venitian-Blinds.jpg",
        title: "Wooden Venitian Blinds",
      },
      {
        link: "../images/products/Vertical-Blinds.jpg",
        title: "Vertical Blinds",
      },
      {
        link: "../images/products/Roman-Blinds.jpg",
        title: "Roman Blinds",
      },
      {
        link: "../images/products/Curtains.jpg",
        title: "Curtains",
      },
      {
        link: "../images/products/Curtain-Fittings.jpg",
        title: "Curtain Fittings",
      },
      {
        link: "../images/products/PVC-Floorings.jpg",
        title: "PVC Floorings",
      },
      {
        link: "../images/products/Sofa-Fabrics.jpg",
        title: "Sofa Fabrics",
      },
    ],
    navLinks: [
      {
        title: "Home",
        url: "/",
      },
      {
        title: "Products",
        url: "#products",
      },
      {
        title: "About",
        url: "#about",
      },
      {
        title: "Contact",
        url: "#contact",
      },
    ],
    mobileMenuActive: false,
  };
  handleMenuNavigation(type) {
    if (type === "open") {
      this.setState({
        mobileMenuActive: true,
      });
    } else {
      this.setState({
        mobileMenuActive: false,
      });
    }
  }
  componentDidMount() {}
  expyears = parseInt(moment().format("YYYY")) - parseInt(1996);
  render() {
    if (this.props.type === "home") {
      return (
        <>
          <Helmet>
            <title>
              ATOZ FURNISHERS - Designing Homes & Offices for decades.
            </title>
          </Helmet>
          <div>
            {this.state.mobileMenuActive ? (
              <div className="mb-menu">
                <div
                  className="icon mb-5"
                  onClick={() => this.handleMenuNavigation("close")}
                >
                  <Icon.X width="40px" height="40px"></Icon.X>
                </div>
                <div className="menu-flx">
                  {this.state.navLinks.map((data, index) => (
                    <a
                      href={data.url}
                      onClick={() => this.handleMenuNavigation("close")}
                      className="list"
                      key={index}
                    >
                      {data.title}
                    </a>
                  ))}
                </div>
              </div>
            ) : (
              <></>
            )}
            <header id="home" className="header-area">
              <div
                className={
                  this.state.scrolledLevelPassed
                    ? "navigation-bar sticky"
                    : "navigation-bar"
                }
              >
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <nav className="navbar navbar-expand-lg">
                        <a className="navbar-brand" href="#">
                          <img src={logo_white} id="scroll-change" alt="Logo" />
                        </a>
                        <div
                          className="resp-ico"
                          onClick={() => this.handleMenuNavigation("open")}
                        >
                          <Icon.Menu
                            width="30px"
                            height="30px"
                            style={{ color: "#fff" }}
                          ></Icon.Menu>
                        </div>
                        <div
                          className="collapse navbar-collapse"
                          id="navbarSupportedContent"
                        >
                          <ul id="nav" className="navbar-nav ml-auto">
                            {this.state.navLinks.map((data, index) => (
                              <React.Fragment key={index}>
                                <li className="nav-item">
                                  <a className="page-scroll" href={data.url}>
                                    {data.title}
                                  </a>
                                </li>
                              </React.Fragment>
                            ))}
                          </ul>
                        </div>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
              <div className="header-banner d-flex align-items-center">
                <div className="overlay"></div>
                <div className="container">
                  <div className="row">
                    <div className="col-xl-8 col-lg-9 col-sm-10">
                      <div className="banner-content">
                        <h4
                          className="sub-title wow fadeInUp"
                          data-wow-duration="1.5s"
                          data-wow-delay="1s"
                        >
                          Your trusted
                        </h4>
                        <h1
                          className="banner-title mt-10 wow fadeInUp"
                          data-wow-duration="1.5s"
                          data-wow-delay="2s"
                        >
                          <span>Interior</span> <br />
                          Design Partner
                        </h1>
                        <a
                          className="banner-contact mt-25 wow fadeInUp"
                          data-wow-duration="1.5s"
                          data-wow-delay="2.3s"
                          href="tel:+919335902863"
                        >
                          Contact Us
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            <section id="about" className="about-area pt-80 pb-130">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="about-image mt-50 clearfix">
                      <div className="single-image float-left">
                        <img
                          src="https://images.pexels.com/photos/90317/pexels-photo-90317.jpeg?auto=compress"
                          alt="About"
                        />
                      </div>
                      <div data-aos="fade-right" className="about-btn">
                        <a className="main-btn" href="#">
                          <span>{this.expyears}</span> Years Of Experience
                        </a>
                      </div>
                      <div className="single-image image-tow float-right">
                        <img
                          src="https://images.pexels.com/photos/1571450/pexels-photo-1571450.jpeg?auto=compress"
                          alt="About"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="about-content mt-45">
                      <h4 className="about-welcome">About Us</h4>
                      <h3 className="about-title mt-10">
                        Masters Of The Craft
                      </h3>
                      <p className="mt-25">
                        We at Atoz Furnishers, have been working for more than 2
                        decades in the furnishings & interior design industry,
                        creating ground breaking products and have completed
                        over 1000+ Projects. <br />
                        <br />
                        Founded in 1996 by Mr. Vinod Lalwani, we've been focused
                        on quality and enhanced service model for our clients
                        with tremendous experience of {this.expyears} years.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              id="products"
              style={{ background: "#F4F5F7" }}
              className="project-area pt-125 pb-130"
            >
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-6">
                    <div className="section-title text-center pb-50">
                      <h2 className="title">Our Products</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="row">
                  {this.state.product_carousel.map((data, index) => (
                    <React.Fragment key={index}>
                      <div className="col-md-4 my-3">
                        <div className="product-col">
                          <img src={data.link} alt={data.title} />
                          <div className="product-info">
                            <span className="h5">{data.title}</span>
                            <Icon.ArrowRight></Icon.ArrowRight>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </section>
            <div className="footer-sec" id="contact">
              <div className="contact-flex pt-5">
                <div className="container my-5">
                  <h5 className="h5 footer-subtext">Let's Talk</h5>
                  <h2
                    className="h2 my-5 wow fadeInUp"
                    data-wow-duration="0.5s"
                    data-wow-delay="0.8s"
                  >
                    Give us a call or send us an email and let's discuss your
                    needs.
                  </h2>
                  <div className="row">
                    <div className="col-md-6 col-lg-6 order-lg-last">
                      <div
                        className="contact-box wow fadeInUp"
                        data-wow-duration="0.5s"
                        data-wow-delay="0.8s"
                      >
                        <h5 className="h5">Contact Info</h5>
                        <p className="lead my-3">
                          B-1 Shivani Palace Faizabad Road Lucknow - 226016.
                        </p>
                        <div className="contact-list">
                          <div className="item">
                            <span className="icon">
                              <Icon.Phone></Icon.Phone>
                            </span>
                            <a href="tel:+919335902863">+91 9335902863</a>
                            <span className="mx-2">/</span>
                            <a href="tel:+918687004007">+91 8687004007</a>
                            <span className="mx-2">/</span>
                            <a href="tel:+919795316655">+91 9795316655</a>
                          </div>
                          <div className="item">
                            <span className="icon">
                              <Icon.Mail></Icon.Mail>
                            </span>
                            <a href="mailto:hello@atozfurnishers.com">
                              hello@atozfurnishers.com
                            </a>
                          </div>
                          <div className="item">
                            <span className="icon">
                              <Icon.MapPin></Icon.MapPin>
                            </span>
                            <a href="https://g.page/ATOZFurnishers">
                              Get Directions
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </>
      );
    } else if (this.props.type === "callbackform") {
      return (
        <>
          <Helmet>
            <title>Request a Callback - ATOZ Furnishers</title>
          </Helmet>
          <div className="container">
            <div className="second-nav">
              <img src={logo_red} />
            </div>
          </div>
        </>
      );
    } else if (this.props.type === "contact") {
      return (
        <>
          <Helmet>
            <title>Contact - ATOZ Furnishers</title>
          </Helmet>
          <Footer />
        </>
      );
    }
  }
}

export default withRouter(Index);
